<script>
    import('../../../public/css/pages/lists.css');
    import { router } from '@inertiajs/svelte';
    import * as DropdownMenu from '$lib/components/ui/dropdown-menu';
    import * as Dialog from '$lib/components/ui/dialog';
    import { Button } from '$lib/components/ui/button';
    import { Icon, EllipsisHorizontal } from 'svelte-hero-icons';
    import { Input } from '$lib/components/ui/input';
    import axios from 'axios';
    import toast from 'svelte-french-toast';

    export let list;
    export let direction;
    export let Cookie;

    let editableName = list.name;
    let showRenameDialog = false;
    let showClearDialog = false;
    let showDeleteDialog = false;

    const updateList = async () => {
        const data = {
            name: editableName,
            type: 'edit',
            list_id: list.id,
        };
        try {
            const response = await axios.post('/my/lists/save', data);
            if (response && response.status === 200) {
                toast.success('List renamed');
                router.reload({ only: ['list'] });
                showRenameDialog = false;
            }
        } catch (error) {
            toast.error('Failed to rename list');
        }
    };

    const clearList = async () => {
        const data = {
            list_id: list.id,
        };

        try {
            const response = await axios.post('/my/lists/members/clear', data);
            if (response && response.status === 200) {
                router.visit('/my/lists', { only: ['lists'] });
                showClearDialog = false;
            }
        } catch (error) {
            toast.error('Failed to clear list');
        }
    };

    const removeList = async () => {
        try {
            const response = await axios.delete(`/my/lists/delete/${list.id}`);
            if (response && response.status === 200) {
                router.visit('/my/lists', { only: ['lists'] });
                showDeleteDialog = false;
            } else {
                toast.error(response.data.error);
            }
        } catch (error) {
            toast.error('Failed to delete list');
        }
    };

    const removeListMember = async (list_id, user_id) => {
        let data = {
            list_id: list_id,
            user_id: user_id,
        };

        try {
            const response = await axios.delete(`/my/lists/members/delete`, { data: data });
            if (response && response.status === 200) {
                toast.success(response.data.message);
                router.reload({ only: ['list'] });
            }
        } catch (error) {
            toast.error('Failed to remove member');
        }
    };
</script>

<div class="row">
    <div class="min-vh-100 border-right col-12 pr-md-0">
        <div class="border-bottom flex justify-between px-3 pb-3 pl-4 pt-4">
            <h5 class="text-truncate mb-0 font-bold {Cookie}">
                {list.name}
            </h5>
            {#if list.isManageable}
                <DropdownMenu.Root>
                    <DropdownMenu.Trigger asChild let:builder>
                        <Button variant="outline" size="sm" class="px-3" builders="{[builder]}">
                            <Icon src="{EllipsisHorizontal}" size="22px" />
                        </Button>
                    </DropdownMenu.Trigger>
                    <DropdownMenu.Content class="w-40">
                        <DropdownMenu.Item on:click="{() => (showRenameDialog = true)}">Rename list</DropdownMenu.Item>
                        <DropdownMenu.Item on:click="{() => (showClearDialog = true)}">Clear list</DropdownMenu.Item>
                        <DropdownMenu.Item on:click="{() => (showDeleteDialog = true)}">Delete list</DropdownMenu.Item>
                    </DropdownMenu.Content>
                </DropdownMenu.Root>

                <!-- Rename Dialog -->
                <Dialog.Root bind:open="{showRenameDialog}">
                    <Dialog.Content>
                        <Dialog.Header>
                            <Dialog.Title>Rename list</Dialog.Title>
                        </Dialog.Header>
                        <div class="py-4">
                            <Input
                                id="list-name"
                                type="text"
                                class="w-full"
                                required
                                placeholder="List name"
                                bind:value="{editableName}"
                            />
                        </div>
                        <Dialog.Footer>
                            <Button variant="outline" on:click="{() => (showRenameDialog = false)}">Cancel</Button>
                            <Button on:click="{updateList}">Save</Button>
                        </Dialog.Footer>
                    </Dialog.Content>
                </Dialog.Root>

                <!-- Clear Dialog -->
                <Dialog.Root bind:open="{showClearDialog}">
                    <Dialog.Content>
                        <Dialog.Header>
                            <Dialog.Title>Clear list</Dialog.Title>
                        </Dialog.Header>
                        <p class="py-4 text-center">
                            Are you sure you want to clear all members out of the current list?
                        </p>
                        <Dialog.Footer>
                            <Button variant="outline" on:click="{() => (showClearDialog = false)}">Cancel</Button>
                            <Button variant="destructive" on:click="{clearList}">Clear</Button>
                        </Dialog.Footer>
                    </Dialog.Content>
                </Dialog.Root>

                <!-- Delete Dialog -->
                <Dialog.Root bind:open="{showDeleteDialog}">
                    <Dialog.Content>
                        <Dialog.Header>
                            <Dialog.Title>Delete list</Dialog.Title>
                        </Dialog.Header>
                        <p class="py-4 text-center">Are you sure you want to delete the current list?</p>
                        <Dialog.Footer>
                            <Button variant="outline" on:click="{() => (showDeleteDialog = false)}">Cancel</Button>
                            <Button variant="destructive" on:click="{removeList}">Delete</Button>
                        </Dialog.Footer>
                    </Dialog.Content>
                </Dialog.Root>
            {/if}
        </div>
        <div class="mx-4 pt-2">
            <div class="list-wrapper mt-2">
                {#if list.members.length > 0}
                    <div class="row">
                        {#each list.members as member}
                            <div class="col-12 col-md-6 col-xl-4">
                                <div
                                    class="suggestion-box card mb-2 !rounded-lg border-0 text-white"
                                    data-memberuserid="{member.id}"
                                >
                                    <a href="/{member.user.username}" class="group">
                                        <div
                                            style="background: url({member.user.cover});"
                                            class="card-img suggestion-header-bg !rounded-lg"
                                        ></div>
                                        <div class="card-img-overlay !rounded-lg p-0">
                                            <div class="h-100 w-100 position-absolute z-index-0 m-0 p-0">
                                                <div class="h-50"></div>
                                                <div
                                                    class="h-50 w-100 d-flex !rounded-b-lg bg-[#000000] bg-opacity-20 backdrop-blur-sm !backdrop-filter transition-all duration-200 group-hover:bg-opacity-25 group-hover:backdrop-blur"
                                                ></div>
                                            </div>
                                            <div class="card-text w-100 h-100 d-flex">
                                                <div
                                                    class="d-flex align-items-center justify-content-center z-index-3 px-3"
                                                >
                                                    <img
                                                        src="{member.user.avatar}"
                                                        alt=""
                                                        class="avatar rounded-circle"
                                                    />
                                                </div>
                                                <div class="w-100 z-index-3 text-truncate">
                                                    <div class="h-50 d-flex flex-row-reverse pr-1">
                                                        {#if list.type === 'follower' ? false : true}
                                                            <button
                                                                class="h-pill h-pill-accent suggestion-card-btn mt-1 rounded !border-none bg-transparent"
                                                                data-toggle="tooltip"
                                                                data-placement="bottom"
                                                                title=""
                                                                on:click|stopPropagation|preventDefault="{() => {
                                                                    removeListMember(member.list_id, member.user_id);
                                                                }}"
                                                                data-original-title="{'Delete'}"
                                                            >
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    fill="none"
                                                                    viewBox="0 0 24 24"
                                                                    stroke-width="1.5"
                                                                    stroke="currentColor"
                                                                    class="h-6 w-6"
                                                                >
                                                                    <path
                                                                        stroke-linecap="round"
                                                                        stroke-linejoin="round"
                                                                        d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                                                                    ></path>
                                                                </svg>
                                                            </button>
                                                        {/if}
                                                    </div>
                                                    <div
                                                        class="h-50 w-100 z-index-3 d-flex flex-column justify-content-center text-truncate pr-2"
                                                    >
                                                        <div class="h6 text-truncate m-0">
                                                            <span
                                                                class="d-flex align-items-center -mb-1 text-lg font-bold text-white"
                                                                >{member.user.name}
                                                                {#if member.user.email_verified_at && member.user.birthdate && member.user.verification && member.user.verification.status == 'verified'}
                                                                    <span
                                                                        data-toggle="tooltip"
                                                                        data-placement="top"
                                                                        title="{'Verified User'}"
                                                                    >
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            fill="none"
                                                                            viewBox="0 0 24 24"
                                                                            stroke-width="1.5"
                                                                            stroke="currentColor"
                                                                            class="h-6 w-6"
                                                                        >
                                                                            <path
                                                                                stroke-linecap="round"
                                                                                stroke-linejoin="round"
                                                                                d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                                                                            ></path>
                                                                        </svg>
                                                                    </span>
                                                                {/if}
                                                            </span>
                                                        </div>
                                                        <div class="text-truncate m-0">
                                                            <span
                                                                href="/{member.user.username}"
                                                                class="text-sm font-light text-white"
                                                                >@{member.user.username}</span
                                                            >
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        {/each}
                    </div>
                {:else}
                    <p class="pl-0 pt-2">No profiles available</p>
                {/if}
            </div>
        </div>
    </div>
</div>
